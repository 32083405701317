import React from 'react';
import Helmet from 'react-helmet';
import Waypoint from 'react-waypoint';
import pic01 from '../assets/images/pic01.jpg';
import Header from '../components/Header';
import Layout from '../components/layout';
import Nav from '../components/Nav';


class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stickyNav: false
    }
  }

  _handleWaypointEnter= () => {
    this.setState(() => ({ stickyNav: false }));
  }

  _handleWaypointLeave = () => {
    this.setState(() => ({ stickyNav: true }));
  }

  render() {

    return (
      <Layout>
        <Helmet>
          <title>GitSpin | Stats for Devs</title>
          <script async defer type="text/javascript" src="https://plausible.io/js/plausible.js"></script>
        </Helmet>

        <Header />

        <Waypoint
          onEnter={this._handleWaypointEnter}
          onLeave={this._handleWaypointLeave}
        >
        </Waypoint>
        <Nav sticky={this.state.stickyNav} />

        <div id="main">

          <section id="intro" className="main">
            <div className="spotlight">
              <div className="content">
                <header className="major">
                  <h2>Tap into the insights hidden in your git repositories.</h2>
                </header>
                <p>GitSpin aggregates git repo data into a metric dashboard
                  to give you insight into your process and code contributions.
                  With GitSpin, you know what contributions came in last
                  week/sprint/month and how it's changed with time.
                </p>
              </div>
              <span className="image"><img src={pic01} alt="" /></span>
            </div>
            <div className="content">
              <ul className="statistics">
                <li className="style1">
                  <span className="icon fa-code-fork"></span>
                  <strong>Pull Requests</strong>
                </li>
                <li className="style2">
                  <span className="icon fa-laptop"></span>
                  <strong>Commit Activity</strong>
                </li>
                <li className="style3">
                  <span className="icon fa-signal"></span>
                  <strong>Line Changes</strong>
                </li>
                <li className="style4">
                  <span className="icon fa-folder-open-o"></span>
                  <strong>Repo Groups</strong>
                </li>
                <li className="style5">
                  <span className="icon fa-diamond"></span>
                  <strong>Code Stability</strong>
                </li>
              </ul>
            </div>
          </section>

          <section id="first" className="main special">
            <header className="major">
              <h2>Empowering developers</h2>
            </header>
            <ul className="features">
              <li>
                <span className="icon major style1 fa-code"></span>
                <h3>Understand your working habits.</h3>
                <p>See at a glance how much code you ship, your pull request activity, and potential bottlenecks.</p>
              </li>
              <li>
                <span className="icon major style3 fa-bullseye"></span>
                <h3>Banish imposter syndrome.</h3>
                <p>You can be that rockstar engineer. Nail the right balance between coding speed and stability.</p>
              </li>
              <li>
                <span className="icon major style5 fa-question"></span>
                <h3>Never draw a blank at standups.</h3>
                <p>Get an easy overview of your activity yesterday, last week, or last month - any time period you fancy, really.</p>
              </li>
            </ul>
          </section>

          <section id="second" className="main special">
            <header className="major">
              <h2>Improving teams</h2>
            </header>
            <ul className="features">
              <li>
                <span className="icon major style1 fa-bar-chart"></span>
                <h3>Improve delivery over time</h3>
                <p>See what your team accomplished last sprint and how velocity is improving. Identify code stability problems that could be holding you back.</p>
              </li>
              <li>
                <span className="icon major style3 fa-users"></span>
                <h3>Upgrade team dynamics.</h3>
                <p>Critical insights into your team's inner process - an overview of commits, change volume, and PRs at a glance. Promote healthy collaboration across projects and people.</p>
              </li>
              <li>
                <span className="icon major style5 fa-diamond"></span>
                <h3>Data driven engineering.</h3>
                <p>Make decisions with objective data. Bring new insights to backlog grooming and sprint planning. You don't have to guess what your team can do in a sprint.</p>
              </li>
            </ul>
          </section>

          <section id="cta" className="main special">
            <header className="major">
              <h2>Get your invite</h2>
              <p>Drop your email and get invited to try GitSpin.</p>
            </header>
            <footer className="major">
            <form action="https://www.getdrip.com/forms/380982246/submissions" method="post" data-drip-embedded-form="380982246">
              <input placeholder="Email Address" type="email" id="drip-email" name="fields[email]" style={{maxWidth: "30rem", marginRight: "auto", marginLeft: "auto"}} />
              <div style={{display: "none"}} aria-hidden="true">
                <label for="website">Website</label><br />
                <input type="text" id="website" name="website" tabindex="-1" autocomplete="false" value="" />
              </div>
              <div style={{paddingTop: "3rem"}}>
                <input className="special" type="submit" value="Sign Up" data-drip-attribute="sign-up-button" />
              </div>
            </form>
            </footer>
          </section>

        </div>

      </Layout>
    )
  }
}

export default Index
