import React from 'react'

const Footer = (props) => {
    var d = new Date()
    return (<footer id="footer">
        <section>
            <h2>Contact</h2>
            <dl className="alt">
                <dt>Email</dt>
                <dd><a href="mailto:support@gitspin.com">support@gitspin.com</a></dd>
                
            </dl>
            {/* <ul className="icons">
                <li><a href="https://twitter.com/gitspin" className="icon fa-twitter alt"><span className="label">Twitter</span></a></li>
                <li><a href="https://github.com/gitspin" className="icon fa-github alt"><span className="label">GitHub</span></a></li>
            </ul> */}
        </section>
        <p className="copyright">Copyright &copy; {d.getFullYear()}</p>
    </footer>);
}

export default Footer
